






import { Component, Vue } from 'vue-property-decorator'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import Common from '../../common/Common'
@Component({
    components: {},
})
export default class CommentAdmin extends Vue {
    comments: any[] = []
    mounted () {
    }
}
